<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="true"
          :showAddNew="false"
          title="Pending PR for Fund Requisition "
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
      />
      <DateQuerySetter @onClickGo="getRequisition">
        <div class="col-12 col-sm-8 col-md-8 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Requester</label>
          <input class="form-control" type="text" name="requester" v-model="requester">
        </div>

        <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
          <label for="colFormLabel" class="col-form-label">Project</label>
          <v-select
              placeholder="Select Project"
              v-model="projectId"
              :options="projects"
              label="name"
              :reduce="name => name.id"
          />
        </div>

        <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
          <label for="colFormLabel" class="col-form-label">Department</label>
          <v-select
              placeholder="Select Department"
              v-model="departmentId"
              :options="departments"
              label="name"
              :reduce="name => name.id"
          />
        </div>

        <div class="col-12 col-sm-8 col-md-8 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Requisition Number</label>
          <input class="form-control" type="text" name="requisitionNumber" v-model="requisitionNumber">
        </div>

        <div class="col-12 col-sm-8 col-md-8 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Quick Search</label>
          <input class="form-control" type="text" name="status" v-model="quick_search">
        </div>
      </DateQuerySetter>
    </div>

    <ListTable
        :requisition-list="requisitionList"
    />
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
  </div>
  <GlobalLoader/>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/procurement/requisition/PendingPRForFRListingTable'
import Pagination from '@/components/atom/Pagination'
import {
  ref,
  inject,
  onMounted,
  computed,
  watch
}                         from 'vue'
import {
  useRouter,
  useRoute
}                         from 'vue-router'
import handleContact      from '@/services/modules/contact'
import handleProjects     from "@/services/modules/procurement/project";
import handleRequisitions from "@/services/modules/procurement/requisition";
import handleDepartments  from "@/services/modules/procurement/organization-settings/department";

const router  = useRouter()
const route   = useRoute()
const loading = ref(false);

const showError              = inject('showError');
const showSuccess            = inject('showSuccess');
const {fetchContactProfiles} = handleContact()
const {fetchSPRequisitionList}  = handleRequisitions();
const {fetchProjects} = handleProjects()
const {fetchDepartmentList} = handleDepartments();

let page = ref(1);
const offset = ref(20);
const loader = ref(false);
const projects = ref([]);
let projectId = ref(null);
const departments = ref([]);
let departmentId = ref(null);
let requisitionList = ref({});
let quick_search = ref(null);
let requester = ref(null);
let requisitionNumber = ref(null);
let companyId = computed(() => route.params.companyId);

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end
  if (requester.value) query += '&requester=' + requester.value
  if (requisitionNumber.value) query += '&requisition_number=' + requisitionNumber.value
  if (projectId.value) query += '&project_id=' + projectId.value
  if (departmentId.value) query += '&department_id=' + departmentId.value
  if (page.value) query += '&page=' + page.value
  return query
}

function onClickAddNewButton() {
  alert('Add New Button clicked');
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

async function getProjects() {
  try {
    const res = await fetchProjects(getQuery());
    if (res.status) {
      projects.value = res.data;
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

async function getDepartments() {
  try {
    const res = await fetchDepartmentList(getQuery());
    if (res.status) {
      departments.value = res.data;
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

async function getRequisition() {

  try {
    const res = await fetchSPRequisitionList(getQuery());
    if (res.status) {
      requisitionList.value = res.data;
    } else {
      requisitionList.value = {};
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

function onPageChange(page) {
  let routeQuery  = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    getRequisition();
  }, 100)
}

onMounted(async () => {
  await getProjects();
  await getDepartments();
  await getRequisition();
})
</script>
